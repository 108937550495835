import React from 'react';
import { FaqCard } from './FaqCard';
export const Faq = () =>  {
    return (
        <div className='pageFaq'>
        <div className='faq'>
            <div className='faqTitle'>FAQ</div>
            <FaqCard question={"Могу ли я обменяться на предмет из другой игры?"} answer={"Да! Наш портал позволяет вам обменивать игровые NFT из какой бы игры они не были. Сменить игру на новую и получить хороший стартовый капитал благодаря нашему сервису еще никогда не было так легко!"} />
            <FaqCard question={"Что гарантирует сохранность моего NFT?"} answer={"В момент создания бартера ваш NFT блокируется системой. Любой игрок, который захочет поменяться с вами сделает тоже самое. И только после этого контракт автоматически произведет обмен. Если за введенное вами время желающих не найдется, ваш NFT вернется к вам так же автоматически."} />
            <FaqCard question={"Я предложил другому игроку обмен, оплатил газ, а он отказался. Что делать?"} answer={"К сожалению, такое возможно, так как NFT только блокируется нами, мы не можем гарантировать, что другой игрок не передумает производить обмен. Однако мы боремся с такими случаями, игрок получит штрафные баллы и не сможет производить новые обмены еще некоторое время."} />
            <FaqCard question={"Могу ли я обменяться на несколько предметов сразу?"} answer={"Мы планируем запуск мультиобмена в ближайшее время. Создавайте целые наборы NFT и меняйтесь на такие же наборы из вашей или другой игры"} />
            <FaqCard question={"Я нашел нужную мне NFT на вашей P2P бирже, но у меня нет NFT которую хочет другой игрок. Что делать?"} answer={"Вы можете написать игроку и предложить ему другой вариант. Или следите за нашими обновлениями, уже скоро мы запустим функционал, который позволит совершать цепочку обменов сразу с несколькими игроками, чтобы все получили желаемое!"} />
            <FaqCard question={"Я хочу начать играть в игру N, но у меня нет NFT для обмена, можно ли взять NFT в аренду?"} answer={"Да! Вы можете взять NFT в аренду в соответствующем разделе сайта, при чем это будет почти бесплатно, ваши деньги только заблокируются на срок аренды, а потом вернутся к вам."} />
            <FaqCard question={"У меня много NFT, но я не хочу менять или продавать их, как я могу заработать на аренде?"} answer={"После размещения вашего NFT на нашей бирже, другой игрок предоставляет залоговые средства, пользуется ей, а его деньги работают на вас. После окончания аренды вы получите % от оборотной прибыли."} />
        </div>
        </div>
    )
}
